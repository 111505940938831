import { CustomLabelComponent } from '#/components/CustomLabelComponent';
import type { TInput } from '@workdigital/component-library-react';
import { CustomCheckbox } from '#/components/CustomCheckbox';

export const elliFields: Record<string, TInput> = {
  privacy: {
    type: 'Checkbox',
    name: 'privacy',
    checked: false,
    value: '',
    // @ts-ignore
    label: (() => {
      return <CustomLabelComponent labelKey={'privacy.label'} />;
    })(),
    inputWrapper: CustomCheckbox
  },
  tandcs: {
    type: 'Checkbox',
    name: 'tandcs',
    checked: false,
    value: '',
    // @ts-ignore
    label: (() => {
      return <CustomLabelComponent labelKey={'tandcs.label'} />;
    })(),
    inputWrapper: CustomCheckbox,
    validations: [
      {
        validationKey: 'tandcs',
        strategy: 'submit',
        validate: (value) => {
          return Boolean(value);
        },
        messages: ['Bitte akzeptieren Sie diesen rechtlichen Hinweis']
      }
    ]
  },
  agreeSmartMeter: {
    type: 'Checkbox',
    name: 'agreeSmartMeter',
    checked: false,
    value: '',
    // @ts-ignore
    label: (() => {
      return <CustomLabelComponent labelKey={'smartmeter.agree.label'} />;
    })(),
    inputWrapper: CustomCheckbox,
    validations: [
      {
        validationKey: 'agreeSmartMeter',
        strategy: 'submit',
        validate: (value) => {
          return Boolean(value);
        },
        messages: ['Bitte akzeptieren Sie diesen rechtlichen Hinweis']
      }
    ]
  }
};

export const elliOrder: string[] = ['tandcs', 'privacy', 'agreeSmartMeter'];
