import type { TClients, TCommodity, TCommodityObject, TConfigs } from '#/types/WorkDigitalBaseTypes';
import defaultConfigs from './configs';
import isEmpty from 'lodash/isEmpty';
import workdigitalConfigs from './clients/workdigital';
import elliConfigs from './clients/elli';
import prokonConfigs from '#configs/clients/prokon.ts';

const configs: Record<TClients, Partial<TConfigs>> = {
  workdigital: workdigitalConfigs,
  elli: elliConfigs,
  prokon: prokonConfigs
};

let cachedConfigs: TConfigs;

/**
 *
 * @param client
 * @param force - force a refresh of the cached configs
 * @returns
 */
const getConfigs = (force = false) => {
  if (!isEmpty(cachedConfigs) && !force) {
    return cachedConfigs;
  }

  const client = import.meta.env.VITE_APP_CLIENT ?? 'default';
  const clientConfig = configs[client as TClients];

  const mergedConfig = Object.assign({}, defaultConfigs, clientConfig);

  cachedConfigs = mergedConfig;

  return mergedConfig;
};

const getDefaultCommodityConfigs = (commodity: TCommodity): TCommodityObject => {
  return <TCommodityObject>defaultConfigs.commodities[commodity];
};

export const getCommodityConfigs = (commodity: TCommodity): TCommodityObject => {
  const configs = getConfigs();
  return configs.commodities[commodity] ?? getDefaultCommodityConfigs(commodity);
};

export default getConfigs;
