import React from 'react';
import ReactDOM from 'react-dom/client';

import 'primeflex/primeflex.scss';
import 'primereact/resources/primereact.min.css';
import './currentTheme';
import { AppResolver } from '#Apps/AppResolver';
import { enableMocking } from './api/mocks/init';
import { StylingContextProvider, addPrimeReactLocale } from '@workdigital/component-library-react';
import { I18nextProvider } from 'react-i18next';
import { i18n, initLocal } from './i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { initSentry } from './sentry/sentry';
import { PrimeReactProvider } from 'primereact/api';
import { ErrorBoundary } from '@sentry/react';

initSentry();

const queryClient = new QueryClient();

addPrimeReactLocale('de', {
  firstDayOfWeek: 1,
  dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
  dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  monthNames: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ],
  monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
  today: 'Heute',
  clear: 'Löschen'
});

enableMocking().then(() => {
  initLocal('de').then(() => {
    ReactDOM.createRoot(document.getElementById('wd-sales')!).render(
      <React.StrictMode>
        <I18nextProvider i18n={i18n}>
          <StylingContextProvider theme={{ InputForm: { classes: 'p-2' } }}>
            <QueryClientProvider client={queryClient}>
              <PrimeReactProvider>
                <ErrorBoundary>
                  <AppResolver />
                </ErrorBoundary>
              </PrimeReactProvider>
            </QueryClientProvider>
          </StylingContextProvider>
        </I18nextProvider>
      </React.StrictMode>
    );
  });
});
