import { CenterContainer } from '@workdigital/component-library-react';
import { ProgressSpinner } from 'primereact/progressspinner';

const LoadingComponent = () => {
  return (
    <CenterContainer>
      <ProgressSpinner />
    </CenterContainer>
  );
};

export default LoadingComponent;
