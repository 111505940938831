import { useTranslation } from 'react-i18next';
interface TCustomLabelComponentProps {
  labelKey: string;
}
const CustomLabelComponent = (props: TCustomLabelComponentProps) => {
  const { t } = useTranslation();
  // @ts-ignore
  return (
    <span
      className={'wd-custom-checkbox-label'}
      // @ts-ignore
      dangerouslySetInnerHTML={{ __html: t(props.labelKey) }}
    ></span>
  );
};

export default CustomLabelComponent;
