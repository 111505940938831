import { type PropsWithChildren, useMemo } from 'react';
import type {
  TCustomer,
  TStepperHandlers,
  TProduct,
  TBillingInfo,
  TProvider,
  TPaymentData,
  TAddressData,
  TDeliveryData,
  TRulesData,
  TSettings,
  TOrderData
} from '#types/WorkDigitalBaseTypes.ts';
import { useState, useEffect } from 'react';
import JourneyContext from './JourneyContext';
import useProducts from '#hooks/queries/useProducts/useProducts.tsx';
import useProviders from '#hooks/queries/useProviders/useProviders.tsx';
import { useTranslation } from 'react-i18next';
import useSettings from '#hooks/queries/useSettings/useSettings.tsx';
import type { TCTAProps } from '#hooks/journey/useSetCTAProps/useSetCTAProps.tsx';
import { getSessionHash } from '#utils/sessionHash.ts';
import { ALL_STEPS } from '#Apps/Journey/utils/steps.ts';
import { getCustomerFormData, getDeliveryFormData, getRulesFormData } from '#state/JourneyState/utils/formData.ts';
import type { TFormErrors } from '@workdigital/component-library-react';
import { parsePropsWidget } from '#state/utils/parsePropsWidget.ts';
import { parsePropsJourney } from '#state/utils/parsePropsJourney.ts';

// @todo improve typing in case there are additional props
const JourneyProvider = ({ children, ...props }: PropsWithChildren) => {
  const [setupProps, setSetupProps] = useState(parsePropsJourney(props));
  const { t } = useTranslation();
  const [formErrors, setFormErrors] = useState<TFormErrors>({} as TFormErrors);
  const [productCode, setProductCode] = useState<string | null>(null);
  const [customer, setCustomer] = useState<TCustomer>({
    firstName: '',
    surname: '',
    salutation: '',
    email: '',
    phone: '',
    birthday: '',
    billingAlternativeAddress: false,
    extra: {}
  });
  const [billingInfo, setBillingInfo] = useState<TBillingInfo>({
    company: '',
    firstName: '',
    surname: '',
    email: ''
  });
  const [addressData, setAddressData] = useState<TAddressData>({
    street: '',
    houseNumber: '',
    zip: '',
    city: ''
  });
  const [paymentData, setPaymentData] = useState<TPaymentData>({
    iban: '',
    bic: '',
    bank: '',
    alternativeAccountHolder: '',
    directDebit: true,
    directDebitConsent: false
  });
  const [deliveryData, setDeliveryData] = useState<TDeliveryData>({
    customerSpecification: '',
    date: '',
    counterNumber: '',
    marketLocationIdentifier: '',
    previousProvider: '',
    customerNumber: '',
    extra: {
      smartMeter: ''
    }
  });
  const [rulesData, setRulesData] = useState<TRulesData>({
    adsPhone: false,
    adsMail: false,
    adsPost: false,
    adsAll: false,
    tandcs: false,
    privacy: false,
    conditions: false,
    conditionsDiscount: false,
    acceptTerms: false,
    agreeECommunication: false,
    permissionToTerminateContract: false,
    extendedData: {},
    uvpCode: null,
    vpCode: null,
    extra: {
      smartMeter: false,
      eAuto: false,
      heatPump: false
    }
  });

  const [extendedData, setExtendedData] = useState<Record<string, unknown>>({});

  const [stepperHandlers, setStepperHandlers] = useState<TStepperHandlers>({
    goTo: (step: string | number) => {
      console.log(step);
    },
    back: () => {},
    currentStepKey: '',
    currentStepNumber: -1,
    hasNextStep: false,
    hasPrevStep: false,
    next: () => {}
  });

  const [ctaProps, setCtaProps] = useState<TCTAProps>({
    validations: { runValidations: () => false }
  });

  const [isDone, setIsDone] = useState(false);
  const [journeyState, setJourneyState] = useState({
    discount: {
      discountCode: '',
      discountValueFromBonusProvider: 0
    },
    productCode: '',
    isBusinessJourney: false
  });

  const [apiLoading, setApiLoading] = useState(false);

  const [orderPlaced, setOrderPlaced] = useState(false);
  const [orderData, setOrderData] = useState({} as TOrderData);

  const [availableProducts, setAvailableProducts] = useState([] as TProduct[]);
  const [availableProviders, setAvailableProviders] = useState([] as TProvider[]);
  const [settings, setSettings] = useState({} as TSettings);

  const isReviewStep = useMemo(() => {
    return stepperHandlers.currentStepKey === ALL_STEPS.REVIEW;
  }, [stepperHandlers.currentStepKey]);

  const [commitStepFunction, setCommitStepFunction] = useState(() => (): boolean | Promise<boolean> => false);

  const { isPending: productsPending, data: products } = useProducts();
  const { isPending: providersPending, data: providers } = useProviders();
  const { isPending: settingsPending, data: settingsData } = useSettings();

  useEffect(() => {
    setApiLoading(productsPending);
    setAvailableProducts(products ?? []);
  }, [products, productsPending]);

  useEffect(() => {
    setAvailableProviders(providers ?? []);
  }, [providers, providersPending]);

  useEffect(() => {
    if (settingsData && !settingsPending) {
      setSettings(settingsData);
    }
  }, [settingsData, settingsPending]);

  const getStepData = (stepKey: string) => {
    switch (stepKey) {
      case ALL_STEPS.PRODUCT:
        return {
          sessionHash: getSessionHash() ?? '',
          productCode: productCode,
          productVersionId: '',
          discountCode: journeyState?.discount?.discountCode ?? ''
        };
      case ALL_STEPS.CUSTOMER:
        return getCustomerFormData(customer, billingInfo, addressData, extendedData, setExtendedData);
      case ALL_STEPS.DELIVERY:
        return getDeliveryFormData(deliveryData, productCode, extendedData, setExtendedData);
      case ALL_STEPS.PAYMENT:
        return {
          sessionHash: getSessionHash() ?? '',
          directDebit: paymentData.directDebit,
          directDebitConsent: paymentData.directDebitConsent,
          alternativeAccountHolder: paymentData.alternativeAccountHolder,
          iban: paymentData.iban?.replace(/\s/g, ''),
          bic: paymentData.bic,
          bank: paymentData.bank
        };
      case ALL_STEPS.LAW:
        return getRulesFormData(rulesData, extendedData, setExtendedData);
    }
  };

  const salutations = useMemo(() => {
    return [
      { value: '1', label: t('app.salutation.mr') },
      { value: '2', label: t('app.salutation.mrs') },
      { value: '9', label: t('app.salutation.neutral') }
    ];
  }, [t]);

  const values = {
    ...props,
    stepperProcess: {
      stepperHandlers,
      setStepperHandlers,
      isDone,
      setIsDone,
      isReviewStep
    },
    getStepData,
    commitStepFunction,
    setCommitStepFunction,
    formErrors,
    setFormErrors,
    data: {
      productCode,
      setProductCode,
      customer,
      setCustomer,
      addressData,
      setAddressData,
      billingInfo,
      setBillingInfo,
      paymentData,
      setPaymentData,
      deliveryData,
      setDeliveryData,
      rulesData,
      setRulesData,
      extendedData,
      setExtendedData
    },
    settings,
    cta: {
      ctaProps,
      setCtaProps
    },
    currentState: {
      productsPending,
      providersPending,
      availableProducts,
      setAvailableProducts,
      availableProviders,
      setAvailableProviders,
      journeyState,
      setJourneyState,
      orderPlaced,
      setOrderPlaced,
      orderData,
      setOrderData,
      salutations,
      apiLoading,
      setApiLoading,
      setupProps,
      setSetupProps
    }
  };

  return <JourneyContext.Provider value={{ ...values }}>{children}</JourneyContext.Provider>;
};

export default JourneyProvider;
