import { useJourneyState } from '#state/JourneyState';
import { Form, type TFormErrors, useForm } from '@workdigital/component-library-react';
import React, { useImperativeHandle, useMemo } from 'react';
import { getFormFields } from './form/RulesForm.fields';
import { getSessionHash } from '#/utils/sessionHash';
import useSession from '#/hooks/queries/useSession/useSession';

const fields = getFormFields();
const RulesForm = React.forwardRef((props: { errors: TFormErrors }, ref) => {
  const context = useJourneyState();
  const sessionHash = getSessionHash();
  const { data: session } = useSession(sessionHash ?? '', context);
  const extendedData = session?.extendedData || {};
  const hiddenKeys = extendedData?.DYNAMIC_TARIFF?.BLUE_METERING === 1 ? [] : ['agreeSmartMeter'];
  const errors = useMemo(() => props.errors, [props.errors]);
  // I'm getting here Type 'TRulesData' is not assignable to type 'TDefaultData' because uvp and vp codes can be null. I'm not sure how to fix this.
  const { formFields, runValidations } = useForm({
    fields,
    // @ts-ignore
    data: context.data.rulesData,
    // @ts-ignore
    setData: context.data.setRulesData,
    errors,
    filters: [
      {
        arguments: {
          hiddenKeys
        },
        type: 'hidden'
      }
    ]
  });

  useImperativeHandle(ref, () => ({
    runValidations
  }));

  return <Form fields={formFields} />;
});

export default RulesForm;
